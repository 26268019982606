<template>
    <v-card>
        <v-app-bar :style="cardTitleBarStyle" flat>
            <v-toolbar-title :style="cardTitleBarTextStyle">Overview <!-- {{ brandName }} --></v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                :color="accentColor"
            ></v-progress-linear> <!-- TODO: use brand accent color instead of green -->
        </v-app-bar>
        <v-divider/>
        <slot/>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import ImageBox from '@/components/ImageBox.vue';

export default {
    components: {
        // ImageBox,
    },
    data: () => ({
        defaultPalette: {
            // primary: '#009688', // teal
            // primaryText: '#ffffff', // white
            // secondary: '#4DB6AC', // teal lighten-2
            // accent: '#A7FFEB', // teal accent-1
            // background: '#E0F2F1', // teal lighten-5
            primary: '#9E9E9E', // grey
            primaryText: '#ffffff', // white
            secondary: '#E0E0E0', // grey lighten-2
            accent: '#B0BEC5', // blue-grey lighten-3
            background: '#F5F5F5', // grey lighten-4
        },
    }),
    computed: {
        ...mapState({
            brand: (state) => state.brand,
            palette: (state) => state.palette,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        brandName() {
            return this.brand?.name ?? ''; // 'Loading...';
        },
        primaryColor() {
            let result;
            if (this.brand && Array.isArray(this.palette?.content?.primary)) {
                result = this.palette.content.primary[0].hex;
            } else {
                result = this.defaultPalette.primary;
            }
            // TODO: input validation that palette primary color is a valid hex color value or html color name
            return result;
        },
        primaryTextColor() {
            return this.defaultPalette.primaryText;
        },
        accentColor() {
            let result;
            if (this.brand && Array.isArray(this.palette?.content?.accent)) {
                result = this.palette.content.accent[0].hex;
            } else {
                result = this.defaultPalette.accent;
            }
            // TODO: input validation that palette accent color is a valid hex color value or html color name
            return result;
        },
        cardTitleBarTextStyle() {
            return `color: ${this.primaryTextColor}`;
        },
        cardTitleBarStyle() {
            return `background-color: ${this.primaryColor}`;
        },
    },
};
</script>
