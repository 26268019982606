<template>
    <v-row justify="center" class="py-5" align="center" style="height: 100%">
        <v-col cols="12" sm="6">
            <ImageBox :alias="this.$route.params.alias" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
            <BrandCard v-if="brand">

                <v-card-text>
                    <p class="text-overline mb-0">Name</p>
                    <p class="mb-0 pb-0">
                        {{ brand.name }}
                    </p>
                    <template v-if="logo">
                        <p class="text-overline mb-0 mt-8">Logo</p>
                        <ImageBox :alias="this.$route.params.alias" intent="logo" mode="light" etag="0" :width="200" contain/>
                    </template>
                    <!-- TODO: logo size variants, screenshots, other images -->
                    <!-- <p class="text-overline mb-0 mt-8">Images</p>
                    <p class="text-caption mb-0 mt-0">Logos, screenshots, etc.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-image', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all images</router-link>
                    </p> -->

                    <!-- TODO: domains, websites, public email contacts, etc. -->

                    <!-- <p class="text-overline mb-0 mt-8">Files</p>
                    <p class="text-caption mb-0 mt-0">Used to import and export data and assets.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-file', params: { accountId: this.$route.params.accountId } }">See all files</router-link>
                    </p> -->

                    <!-- TODO: mailboxes for people to contact the brand (code will be similar to email origins, but for different purpose) -->
                    <!-- <p class="text-overline mb-0 mt-8">Email Contacts</p>
                    <p class="text-caption mb-0 mt-0">Mailboxes customers can use to reach you, such as 'hello@example.com'.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-email-contact', params: { accountId: this.$route.params.accountId } }">See all email contacts</router-link>
                    </p> -->
                    <!-- TODO: document templates (docx, odt, xlsx, ods, pptx, odp, etc.) -->
                    <!-- <p class="text-overline mb-0 mt-8">Document templates</p>
                    <p class="text-caption mb-0 mt-0">Branded document templates to share with your team.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-document-template', params: { accountId: this.$route.params.accountId } }">See all email templates</router-link>
                    </p> -->
                    <!-- TODO: website list -->
                    <!-- <p class="text-overline mb-0 mt-8">Websites</p>
                    <p class="text-caption mb-0 mt-0">Websites used to interact with your customers.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-website', params: { accountId: this.$route.params.accountId } }">See all websites</router-link>
                    </p> -->

                    <template v-if="brand.attr.unsubscribe_link_href">
                    <p class="text-overline mb-0 mt-8">Communication preferences</p>
                        <TextLink :href="brand.attr.unsubscribe_link_href" target="_blank" id="unsubscribe">Unsubscribe from non-essential email communication</TextLink>
                    </template>

                </v-card-text>
            </BrandCard>

            <v-expansion-panels class="mt-8" v-ifdev>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                        Brand Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <pre>{{ JSON.stringify(this.brand, null, 2) }}</pre>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                        Palette Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <pre>{{ JSON.stringify(this.palette, null, 2) }}</pre>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex';
import BrandCard from '@/components/BrandCard.vue';
import ImageBox from '@/components/ImageBox.vue';
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        BrandCard,
        ImageBox,
        TextLink,
    },
    data: () => ({
    }),
    computed: {
        ...mapState({
            brand: (state) => state.brand,
            palette: (state) => state.palette,
        }),
        isViewReady() {
            return this.brand !== null;
        },
    },
    methods: {
        // async loadBrand() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { loadBrand: true });
        //         const response = await this.$client.main().brand.get({ alias: this.$route.params.alias });
        //         console.log(`loadBrand: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.brand = response;
        //             // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
        //             // this.brand.comment ??= '';
        //             // this.brand.reply_to ??= '';
        //         } else {
        //             // TODO: redirect back to account list? show a not found message?
        //             console.error('failed to load brand');
        //         }
        //     } catch (err) {
        //         console.error('failed to load brand', err);
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { loadBrand: false });
        //     }
        // },
        // async loadPalette() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { loadPalette: true });
        //         const response = await this.$client.main().palette.get({ alias: this.$route.params.alias, mode: 'light', media_type: 'screen' });
        //         console.log(`loadPalette: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.palette = response;
        //         } else {
        //             console.error('failed to load palette');
        //         }
        //     } catch (err) {
        //         console.error('failed to load palette', err);
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { loadPalette: false });
        //     }
        // },
    },
    mounted() {
        // this.loadBrand();
        // this.loadPalette();
    },
};
</script>
